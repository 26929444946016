<template>
	<div>
		>
		<meta http-equiv="Content-Type" content="text/html; charset=euc-kr" />
		<meta http-equiv="Cache-Control" content="no-cache" />
		<meta http-equiv="Expires" content="0" />
		<meta http-equiv="Pragma" content="no-cache" />
		<form
			@submit.prevent="submitForm"
			ref="payform"
			name="payform"
			hidden="true"
			v-bind:action="getActionUrl()"
			method="post"
			accept-charset="euc-kr"
		>
			<input type="text" v-model="payRequest.cpid" name="CPID" />
			<input type="text" v-model="payRequest.orderNo" name="ORDERNO" />
			<input type="text" v-model="payRequest.productType" name="PRODUCTTYPE" />
			<input type="text" v-model="payRequest.billType" name="BILLTYPE" />
			<input type="text" v-model="payRequest.amount" name="AMOUNT" />
			<input type="text" v-model="payRequest.productName" name="PRODUCTNAME" />

			<input type="text" v-model="payRequest.email" name="EMAIL" />
			<input type="text" v-model="payRequest.userId" name="USERID" />
			<input type="text" v-model="payRequest.userName" name="USERNAME" />
			<input type="text" v-model="payRequest.productCode" name="PRODUCTCODE" />
			<input type="text" v-model="payRequest.cpquota" name="CPQUOTA" />

			<input type="text" v-model="payRequest.reservedIndex1" name="RESERVEDINDEX1" />
			<input type="text" v-model="payRequest.reservedIndex2" name="RESERVEDINDEX2" />
			<input type="text" v-model="payRequest.reservedString" name="RESERVEDSTRING" />

			<input type="text" v-model="payRequest.returnUrl" name="RETURNURL" />
			<input type="text" v-model="payRequest.homeUrl" name="HOMEURL" />
			<input type="text" v-model="payRequest.directResultFlag" name="DIRECTRESULTFLAG" />
			<input type="text" v-model="payRequest.closeUrl" name="CLOSEURL" />
			<input type="text" v-model="payRequest.failUrl" name="FAILURL" />

			<input type="text" v-model="payRequest.mobileCompanyList" name="MOBILECOMPANYLIST" />
			<input type="text" v-model="payRequest.cardList" name="CARDLIST" />
			<input type="text" v-model="payRequest.hideCardList" name="HIDECARDLIST" />
			<input type="text" v-model="payRequest.taxFreecd" name="TAXFREECD" />
			<input type="submit" />
		</form>
	</div>
</template>

<script>
export default {
	data() {
		var payMethodInfo = JSON.parse(localStorage.getItem('payMethodInfo'));

		return {
			payRequest: {
				type: payMethodInfo.payType,
				actionUrl: '',

				// mandatory~
				cpid: window.__env.code.CP_ID,
				autoCpid: window.__env.code.CP_ID,
				orderNo: 'DAOU_SAAS_' + payMethodInfo.orderId,
				productType: 1,
				billType: 1,
				taxFreecd: '00',
				//amount: 1000, //결제금액
				amount: payMethodInfo.amount, //결제금액
				productName: payMethodInfo.orderSummary, // summary
				// ~mandatory

				cardCode: '',
				allotMon: '',
				ipAddress: '',
				userId: '', //
				userName: payMethodInfo.corporateName, // 상호명
				productCode: '',
				telNo1: '',
				telNo2: '',
				orderReserved: '',
				reservedIndex1: '',
				reservedIndex2: '',
				reservedString: '',
				returnUrl: '',
				homeUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?success=true&close=true',
				closeUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?close=true',
				failUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?fail=true&close=true',
				directResultFlag: 'Y',
				hideCardList: '',
				cardList: '',
				popupType: '',
				setLog: '',

				cpquota: '', // card only

				userSsn: '', //생년월일 8자리
				checkSsnyn: '', //주민번호 체크 여부
				mobileCompanyList: '',

				//월 자동 결제
				payMethod: '', // 입력하지 않아도됨
				autoMonths: 0, //  1 로 픽스
				cardNo: '', // 카드번호
				expiredt: '', // 월+년 (format : yyyymm)
				cardAuth: '', // 법인사업자번호 (6자리)
				cardPassword: '', // 앞에 2자리

				email: '',
			},
		};
	},

	mounted() {
		this.submitForm();
	},

	methods: {
		submitForm() {
			this.$refs.payform.submit();
		},
		getActionUrl() {
			// document.charset = 'euc-kr';
			return window.__env.code.RTT_URL;
		},
	},
};
</script>

<style></style>
